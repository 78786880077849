  import React, { useState, useRef, useContext, useEffect } from 'react';
  import { View, FlatList, TouchableOpacity, Text,Image, StyleSheet,ImageBackground, TextInput, RefreshControl, ActivityIndicator, Button, Dimensions, Alert, Window, Platform } from 'react-native';
  import colors from '../config/colors';
  import { Formik, ErrorMessage } from 'formik'
  import authApi from '../api/auth';
  import {jwtDecode} from 'jwt-decode'
  import AuthContext from '../auth/context';
  import * as Yup from 'yup'
  import AsyncStorage from '@react-native-async-storage/async-storage';
  import services from '../api/services';
  const SCREEN_HEIGHT = Dimensions.get('window').height;
  import * as Clipboard from 'expo-clipboard';
  import SmallDispoModal from '../components/smallDispoModal';
  
  export default function Landing(){
  
      //for cpoying email to clipboard and calling disposable notification
      const emailToCopy = "info@wedajlms.com"; // The email or static text to be copied
      const [modalVisible, setModalVisible] = useState(false);
    
      const copyToClipboard = async () => {
        await Clipboard.setStringAsync(emailToCopy);
        setModalVisible(true);
      };
    
      const intervalRef=useRef(null)
  
      const clearExistingInterval = () => {
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
          intervalRef.current = null;
        }
      };
  
      const authContext = useContext(AuthContext)
  
      const [loggingIn, setLoggingIn] = useState(false);
      const [loginFailed, setLoginFailed] = useState(false)
      const [trigerForgotPassword, setTrigerForgotPassword] = useState(false)
  
      const [initialPhoneNumber, setInitialPhoneNumber] = useState('');
  
      useEffect(() => {
          const fetchPhoneNumber = async () => {
              try {
                  const storedPhoneNumber = await AsyncStorage.getItem('phoneNumber');
                  if (storedPhoneNumber) {
                      setInitialPhoneNumber(storedPhoneNumber);
                  }
              } catch (error) {
                  console.error('Error retrieving phone number:', error);
              }
          };
          fetchPhoneNumber();
      }, []);
  
  
      const handleSubmit = async ({ email, password }) => {
        //   setLoggingIn(true);
        //   try {
        //     const result = await authApi.login(email, password);
        //     if (result.status === 200) {
        //       const token = result.data;
        //       const decodedToken = jwtDecode(token);
        //       const user = decodedToken.users[0];
        //       const expirationTime = decodedToken.exp * 1000;
        
        //       // console.log('Token will expire in:', Math.round((expirationTime - Date.now())/1000), 'seconds');
        
        //       await AsyncStorage.setItem('user', JSON.stringify(user));
        //       await AsyncStorage.setItem('token', token);
        //       await AsyncStorage.setItem('phoneNumber', email);
        
        //       authContext.setUser(user);
        //       authContext.setToken(token);
        //       setLoggingIn(false);
  
        //       authContext.startExpirationTimer(expirationTime);
  
        //     } else {
        //       setLoginFailed(true);
        //       setLoggingIn(false);
        //       console.log('Login failed:', result);
        //     }
        //   } catch (error) {
        //     setLoggingIn(false);
        //     setLoginFailed(true);
        //     console.error('Login error:', error.response?.data || error.message);
        //   }
      };
  
      const validationSchema = Yup.object().shape({
          email: Yup.string().required().label("Phone number"),
          password: Yup.string().required().label("Password")
      })
  
      const [phoneFornewPassword, setPhoneforNewPassword] = useState('')
      const [initiateReset, setInitiatereset] = useState(false)
   
      const handleResetPassword = async () => {
          if (phoneFornewPassword == '') {
              alert("Please type in your phone number");
              return;
          }
          else{
              setInitiatereset(true)
          try {
              const response = await services.resetPassFromLogin(phoneFornewPassword);
              if (response.status === 200) {
                  setInitiatereset(false)
                  alert("Password reset successful.");
                  setTrigerForgotPassword(false)
              } else {
                  alert("An error occurred. Please try again later.");
                  setInitiatereset(false)
              }
          } catch (error) {
              alert("An error occurred. Please try again later.");
              setInitiatereset(false)
          }
      }
      }
  
      return(
          <View style={styles.landingContainer}>
              <View style={styles.boxesConainer}>
                 <View style={styles.leftBox}>
                      {/* <ImageBackground
                          source={require('../assets/Header Image.png')}
                          style={styles.Image_Background}
                          >
                              <View style={styles.container}>
                              <Text style={styles.text}>Hello, World!</Text>
                          </View> 
                      </ImageBackground> */}
                      {/* <Image
                          source={require('../assets/Header Image.png')}
                          style={styles.Image_Background}
                      /> */}
                      <View></View>
                      <View style={styles.logoAndHeaderCotnainer}>
                      <Image
                        source={require('../assets/icon-copy.png')}
                        style={styles.logo}
                        />
                          <View>
                              {/* <Text style={styles.logoText}>Wedaj TMS</Text>
                              <Text style={styles.LogoSubText}>By Wedaj Engineering</Text> */}
                              <Text style={styles.logoText}>እቁብ ደብተር</Text>
                            <Text style={styles.logoText}>Equb Debter</Text>
                          </View>
                      </View>
                      {/* <View style={styles.footerTextContainer}>
                          <Text style={styles.footerText}>0910 873030</Text>
                          <Text style={styles.text}>        </Text>
                          <TouchableOpacity style={styles.footerText} onPress={copyToClipboard}>
                              <Text style={styles.footerText}>{emailToCopy}</Text>
                          </TouchableOpacity>
                              <SmallDispoModal message="Copied to Clipboard!" visible={modalVisible} onHide={() => setModalVisible(false)}/>
                      </View>  */}
                  </View>
                  <View style={styles.rightBox}>
                      {!trigerForgotPassword?
                      <>
              <Formik
                  initialValues={{ email: initialPhoneNumber, password: '' }}
                  onSubmit={handleSubmit}
                  validationSchema={validationSchema}
                  enableReinitialize // Ensures the form is reinitialized with new values
              >
                  {({ handleChange, handleSubmit, values, errors, touched, setFieldValue }) => (
                      <>
                          <View style={styles.formContainer}>
                              <TextInput
                                  style={styles.InputField}
                                  onChangeText={(text) => {
                                      setFieldValue('email', text);
                                  }}
                                  placeholder="Phone Number"
                                  placeholderTextColor="#999"
                                  value={values.email}
                                  editable={true}
                                  textContentType='telephoneNumber'
                                  keyboardType='phone-pad'
                              /><TextInput
                                  style={styles.InputField}
                                  onChangeText={handleChange('password')}
                                  blurOnSubmit={true}
                                  placeholder="Password"
                                  placeholderTextColor={colors.secondary}
                                  textContentType='password'
                                  secureTextEntry
                              ></TextInput>
                      </View>
                      <View style={styles.bottonsContainer}>
                          {/* <View style={styles.errorText}>{touched.password && <Text style={styles.errorText}>{errors.password}</Text>}</View> */}
                          <View style={styles.errorText}>{loginFailed ? <Text style={styles.errorText}>Invalid credentials</Text> : null}</View>
                          <TouchableOpacity onPress={handleSubmit} style={styles.loginButton}>
                              {loggingIn ? 
                                  <ActivityIndicator size="small" color={colors.white} /> 
                                  : 
                                  <><Text style={styles.buttonText}>Login</Text></>
                              }
                          </TouchableOpacity>
                      <TouchableOpacity onPress={()=>setTrigerForgotPassword(true)}><Text style={styles.clickableText}>Forgot password?</Text></TouchableOpacity>
                      </View>
                      </>
                              )}
                      </Formik>
                      </>
                      :
                      <>
                          <TextInput
                              style={styles.InputField}
                              // onBlur={() => setFieldTouched("User name")}
                              onChangeText={setPhoneforNewPassword}
                              blurOnSubmit={true}
                              placeholder="Your Phone Number 09_ _ _ _ _ _ _ _"
                              placeholderTextColor={colors.secondary}
                              // keyboardType='numeric'
                              textContentType='telephoneNumber'
                          ></TextInput>
                              <TouchableOpacity onPress={handleResetPassword} style={styles.loginButton}>
                              {initiateReset ? 
                                  <ActivityIndicator size="small" color={colors.white} /> 
                                  : 
                                  <><Text style={styles.buttonText}>Reset</Text></>
                              }
                          </TouchableOpacity>
                          <TouchableOpacity onPress={()=>setTrigerForgotPassword(false)}><Text style={styles.clickableText}>Cancel</Text></TouchableOpacity>
                      </>
                  }
                  </View>
              </View>
          </View>
      )
  }
  
  const styles = StyleSheet.create({
      landingContainer:{
          width:'100%',
          height: SCREEN_HEIGHT,
          flexDirection: 'column',
          backgroundColor: colors.offwhite2,
          alignItems:'center',
          justifyContent:'center',
      },
      boxesConainer:{
          width:'66%',
          height:'45%',
          backgroundColor:colors.ForeGroundWhite,
          borderRadius:10,
          elevation:10,
          alignItems:'center',
          justifyContent:'center',
          flexDirection:'row',
          // padding:10,
          shadowColor:colors.primary,
      },
      leftBox:{
          width:'40%',
          height:'100%',
          alignItems:'center',
          justifyContent:'center',
          backgroundColor:colors.PrimaryBck,
          justifyContent:'space-between',
          flexDirection:'column',
          paddingBottom:10,
          borderBottomLeftRadius:10,
          borderTopLeftRadius:10,
      },
      logoIcon:{
          width:'50%',
          height:'100%',
          // borderTopLeftRadius:10,
          // borderBottomLeftRadius:10,
      },
      logoAndHeaderCotnainer:{
          flexDirection:'row',
          alignItems:'center',
          justifyContent:'center',
          //backgroundColor:'orange',
          flex:1,
          // width:'100%',
      },
       logo:{ 
        width: 70,
        height: 70,
        borderRadius:16,
        alignSelf:'center',
        marginRight:10
      },
      logoText:{
          fontSize:25,
          fontFamily:'Roboto-Medium',
          color:colors.AppCanvasMain
      },
      LogoSubText:{
          fontSize:19,
          fontFamily:'Roboto-Light',
          color:colors.AppCanvasMain
      },
      footerTextContainer:{
          flexDirection:'row',
          width:'100%',
          alignItems:'center',
          justifyContent:'center',
      },
      footerText:{
          fontFamily:'Roboto-Light',
          //fontWeight:300,
          fontSize:16,
          color:colors.AppCanvasMain
      },
      rightBox:{
          width:'60%',
          height:'100%',
          alignItems:'center',
          justifyContent:'center'
      },
      iconContainer:{},
      formContainer:{},
      InputField:{
          width:350,
          height:40,
          borderWidth:1,
          borderColor:colors.baby_light_blue,
          borderRadius:5,
          paddingLeft:15,
          fontSize:19,
          color:colors.primary,
          placeholderTextColor:colors.Grey,
          fontFamily:'Roboto-Light',
          marginBottom:16,
      },
      errorText:{
          fontSize:16,
          color:colors.red,
          alignSelf:'center'
      },
      bottonsContainer:{
          width:350,
          height:40,
      },
      loginButton:{
          width:350,
          height:40,
          borderWidth:1,
          borderRadius:5,
          justifyContent:'center',
          alignItems:'center',
          backgroundColor:colors.PrimaryBck
      },
      buttonText:{ 
          fontSize:18,
          fontFamily:'Roboto-Medium',
          color:colors.AppCanvasMain,
      },
      clickableText:{
          // fontSize:
          fontFamily:'Roboto-Light',
          //backgroundColor:'orange',
          marginTop:7,
          alignSelf:'center'
      },
  })